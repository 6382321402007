/**
 * Class selection list option
 */
export default class SelectionListOption {

    /**
     * Constructor
     */
    constructor(value, text, selected) {
        this.value = String(value || "");
        this.text = String(text || "");
        this.selected = !!selected;
    }

}